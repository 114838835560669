.main, .drawer {
	height: 100%;
}
body {
	margin: 0;
}

@media (min-height: 500px) {
	.main, .drawer {
		height: calc(100% - 30px);
	}
	body {
		margin: 8px;
	}
}

@keyframes fadein {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}

/* scrollbar */
::-webkit-scrollbar-track {
	background-color: #ccc;
	border-radius: 5px;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #ccc;
}

::-webkit-scrollbar-thumb {
	background-color: #575757;
	border-radius: 5px;
}

